/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // =============================================================================
  // ADMIN LAYOUT MENU
  // =============================================================================
  {
    url: '/admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'admin-dashboard'
  },
  {
    header: 'Orders Admin',
    i18n: 'Orders',
    role: 'admin',
    icon: 'PackageIcon',
    items: [
      {
        url: '/admin/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'orders',
        icon: 'PackageIcon'
      },
      {
        url: '/admin/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'returns',
        icon: 'BoxIcon'
      },
      {
        url: '/admin/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'exchanges',
        icon: 'RepeatIcon'
      },
      {
        url: '/admin/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'cash-collection',
        icon: 'DollarSignIcon'
      },
      {
        url: '/admin/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'supplies-requests',
        icon: 'PackageIcon'
      }
    ]
  },
  {
    header: 'Fulfillment Admin',
    i18n: 'Fulfillment',
    role: 'admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/admin/fulfillment/inventory',
        name: 'Inventory',
        i18n: 'Inventory',
        icon: 'ListIcon',
        slug: 'fc-operations-supervisor-dashboard'
      }
    ]
  },
  {
    header: 'Accounts',
    i18n: 'Accounts',
    role: 'admin',
    icon: 'LayersIcon',
    items: [
      {
        url: '/admin/accounts/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'pricing-packages',
        icon: 'DollarSignIcon'
      },
      {
        url: '/admin/accounts/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon'
      },
      {
        url: '/admin/accounts/blacklist',
        name: 'Blacklist',
        i18n: 'Blacklist',
        slug: 'black-list',
        icon: 'UserXIcon'
      }
    ]
  },
  {
    header: 'Operations',
    i18n: 'Delivery Operations',
    role: 'admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/admin/management/warehouses',
        name: 'Warehouses',
        i18n: 'Warehouses',
        slug: 'warehouses',
        icon: 'DatabaseIcon'
      },
      {
        url: '/admin/management/zone-groups',
        name: 'Zone Groups',
        i18n: 'Zone Groups',
        slug: 'Zone Groups',
        icon: 'MapPinIcon'
      },
      {
        url: '/admin/management/holidays',
        name: 'Holidays',
        i18n: 'Holidays',
        slug: 'holidays',
        icon: 'SmileIcon'
      }
    ]
  },
  {
    header: 'FC Operations',
    i18n: 'Fulfillment Operations',
    role: 'admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/admin/fulfillment/fc-operations-supervisor',
        name: 'FC Operations Supervisor',
        i18n: 'FC Operations Supervisor',
        slug: 'FC Operations Supervisor',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/fulfillment/fc-receiving-agent',
        name: 'FC Receiving Agent',
        i18n: 'FC Receiving Agent',
        slug: 'FC Receiving Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/fulfillment/fc-inbounding-agent',
        name: 'FC Inbounding Agent',
        i18n: 'FC Inbounding Agent',
        slug: 'FC Inbounding Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/fulfillment/fc-storing-agent',
        name: 'FC Storing Agent',
        i18n: 'FC Storing Agent',
        slug: 'FC Storing Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/fulfillment/fc-packing-agent',
        name: 'FC Packing Agent',
        i18n: 'FC Packing Agent',
        slug: 'FC Packing Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/fulfillment/fc-picking-agent',
        name: 'FC Picking Agent',
        i18n: 'FC Picking Agent',
        slug: 'FC Picking Agent',
        icon: 'UsersIcon'
      }
    ]
  },
  {
    header: 'Roles',
    i18n: 'Roles',
    role: 'admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/admin/accounts/ops-admins',
        name: 'OPS Admins',
        i18n: 'OPS Admins',
        slug: 'OPS Admins',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/accounts/commercial-admins',
        name: 'Commercial Admins',
        i18n: 'Commercial Admins',
        slug: 'Commercial Admins',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/accounts/team-leaders',
        name: 'Heads Of Fleet',
        i18n: 'Heads Of Fleet',
        slug: 'heads of fleet',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/accounts/warehouse-agents',
        name: 'Captains',
        i18n: 'Captains',
        slug: 'captains',
        icon: 'WatchIcon'
      },
      {
        url: '/admin/accounts/merchant-agents',
        name: 'Merchant Agents',
        i18n: 'Merchant Agents',
        slug: 'merchant agents',
        icon: 'TruckIcon'
      },
      {
        url: '/admin/accounts/customer-agents',
        name: 'Customer Agents',
        i18n: 'Customer Agents',
        slug: 'customer agents',
        icon: 'TruckIcon'
      },
      {
        url: '/admin/accounts/travellers',
        name: 'Transporters',
        i18n: 'Transporters',
        slug: 'transporters',
        icon: 'TruckIcon'
      },
      {
        url: '/admin/accounts/customer-support',
        name: 'Customer Support',
        i18n: 'Customer Support',
        slug: 'customer support',
        icon: 'PhoneCallIcon'
      },
      {
        url: '/admin/accounts/accounting',
        name: 'Accounting',
        i18n: 'Accounting',
        slug: 'Accounting',
        icon: 'DollarSignIcon'
      },
      {
        url: '/admin/accounts/growth-hackers',
        name: 'Growth Hacking',
        i18n: 'Growth Hacking',
        slug: 'growth hacking',
        icon: 'UsersIcon'
      },
      {
        url: '/admin/accounts/sales',
        name: 'Sales',
        i18n: 'Sales',
        slug: 'sales',
        icon: 'TrendingUpIcon'
      }
    ]
  },
  // =============================================================================
  // CUSTOMER SUPPORT LAYOUT MENU
  // =============================================================================
  {
    url: '/customer-support/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'customer-support-dashboard'
  },
  {
    url: '/customer-support/accounts/customers-support',
    name: 'Customer Support',
    i18n: 'Customer Support',
    icon: 'PhoneCallIcon',
    slug: 'customer-support-customers-support'
  },
  {
    header: 'Operations Customer',
    i18n: 'Operations',
    role: 'customer-support',
    icon: 'PackageIcon',
    items: [
      {
        url: '/customer-support/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'orders',
        icon: 'PackageIcon'
      },
      {
        url: '/customer-support/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'returns',
        icon: 'BoxIcon'
      },
      {
        url: '/customer-support/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'exchanges',
        icon: 'RepeatIcon'
      },
      {
        url: '/customer-support/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'cash-collection',
        icon: 'DollarSignIcon'
      },
      {
        url: '/customer-support/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'supplies-requests',
        icon: 'PackageIcon'
      }
    ]
  },
  {
    header: 'Fulfillment Support',
    i18n: 'Fulfillment',
    role: 'customer-support',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/customer-support/fulfillment/inventory',
        name: 'Inventory',
        i18n: 'Inventory',
        icon: 'ListIcon',
        slug: 'fc-operations-supervisor-dashboard'
      }
    ]
  },
  {
    header: 'Accounts Support',
    i18n: 'Accounts',
    icon: 'LayersIcon',
    role: 'customer-support',
    items: [
      {
        url: '/customer-support/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'pricing-packages',
        icon: 'DollarSignIcon'
      },
      {
        url: '/customer-support/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon'
      }
    ]
  },
  // =============================================================================
  // GROWTH HACKER LAYOUT MENU
  // =============================================================================
  {
    url: '/growth-hacker/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'growth-hacker-dashboard'
  },
  {
    header: 'Discounts Groth Hacker',
    i18n: 'Discounts',
    icon: 'LayersIcon',
    role: 'growth-hacker',
    items: [
      {
        url: '/growth-hacker/promo-codes',
        name: 'Promo Codes',
        i18n: 'Promo Codes',
        slug: 'Promo Codes',
        icon: 'PercentIcon'
      },
      {
        url: '/growth-hacker/automated-codes',
        name: 'Automated Codes',
        i18n: 'Automated Codes',
        slug: 'Automated Codes',
        icon: 'PercentIcon'
      },
      {
        url: '/growth-hacker/exception-discounts',
        name: 'Exception Discounts',
        i18n: 'Exception Discounts',
        slug: 'exception discounts',
        icon: 'PercentIcon'
      }
    ]
  },
  // =============================================================================
  // FC OPERATIONS SUPERVISOR LAYOUT MENU
  // =============================================================================

  // =============================================================================
  // SALES LAYOUT MENU
  // =============================================================================
  {
    url: '/sales/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'sales-dashboard'
  },
  {
    header: 'Operations Sales',
    i18n: 'Operations',
    role: 'sales',
    icon: 'PackageIcon',
    items: [
      {
        url: '/sales/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'orders',
        icon: 'PackageIcon'
      },
      {
        url: '/sales/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'returns',
        icon: 'BoxIcon'
      },
      {
        url: '/sales/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'exchanges',
        icon: 'RepeatIcon'
      },
      {
        url: '/sales/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'cash-collection',
        icon: 'DollarSignIcon'
      },
      {
        url: '/sales/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'supplies-requests',
        icon: 'PackageIcon'
      }
    ]
  },
  {
    header: 'Accounts Sales',
    i18n: 'Accounts',
    icon: 'LayersIcon',
    role: 'sales',
    items: [
      {
        url: '/sales/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'pricing-packages',
        icon: 'DollarSignIcon'
      },
      {
        url: '/sales/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon'
      }
    ]
  },
  // =============================================================================
  // OPS ADMIN LAYOUT MENU
  // =============================================================================
  {
    url: '/ops-admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'ops-admin-dashboard'
  },
  {
    header: 'Agents OPS',
    i18n: 'Training',
    role: 'ops-admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/ops-admin/agents/agent-training',
        name: 'Agent Training',
        i18n: 'Agent Training',
        slug: 'Agent Training',
        icon: 'SmileIcon'
      }
    ]
  },
  {
    header: 'Operations OPS',
    i18n: 'Delivery Operations',
    role: 'ops-admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/ops-admin/management/holidays',
        name: 'ops-admin-holidays',
        i18n: 'Holidays',
        slug: 'holidays',
        icon: 'SmileIcon'
      }
    ]
  },
  {
    header: 'FC Operations OPS',
    i18n: 'Fulfillment Operations',
    role: 'ops-admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/ops-admin/fulfillment/fc-operations-supervisor',
        name: 'FC Operations Supervisor',
        i18n: 'FC Operations Supervisor',
        slug: 'FC Operations Supervisor',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/fulfillment/fc-receiving-agent',
        name: 'FC Receiving Agent',
        i18n: 'FC Receiving Agent',
        slug: 'FC Receiving Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/fulfillment/fc-inbounding-agent',
        name: 'FC Inbounding Agent',
        i18n: 'FC Inbounding Agent',
        slug: 'FC Inbounding Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/fulfillment/fc-storing-agent',
        name: 'FC Storing Agent',
        i18n: 'FC Storing Agent',
        slug: 'FC Storing Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/fulfillment/fc-packing-agent',
        name: 'FC Packing Agent',
        i18n: 'FC Packing Agent',
        slug: 'FC Packing Agent',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/fulfillment/fc-picking-agent',
        name: 'FC Picking Agent',
        i18n: 'FC Picking Agent',
        slug: 'FC Picking Agent',
        icon: 'UsersIcon'
      }
    ]
  },
  {
    header: 'Roles OPS',
    i18n: 'Roles',
    role: 'ops-admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/ops-admin/accounts/team-leaders',
        name: 'Heads Of Fleet',
        i18n: 'Heads Of Fleet',
        slug: 'heads of fleet',
        icon: 'UsersIcon'
      },
      {
        url: '/ops-admin/accounts/warehouse-agents',
        name: 'Captains',
        i18n: 'Captains',
        slug: 'captains',
        icon: 'WatchIcon'
      },
      {
        url: '/ops-admin/accounts/merchant-agents',
        name: 'Merchant Agents',
        i18n: 'Merchant Agents',
        slug: 'merchant agents',
        icon: 'TruckIcon'
      },
      {
        url: '/ops-admin/accounts/customer-agents',
        name: 'Customer Agents',
        i18n: 'Customer Agents',
        slug: 'customer agents',
        icon: 'TruckIcon'
      },
      {
        url: '/ops-admin/accounts/travellers',
        name: 'Transporters',
        i18n: 'Transporters',
        slug: 'transporters',
        icon: 'TruckIcon'
      }
    ]
  },
  // =============================================================================
  // COMMERCIAL ADMIN LAYOUT MENU
  // =============================================================================
  {
    url: '/commercial-admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'commercial-admin-dashboard'
  },
  {
    url: '/commercial-admin/search',
    name: 'search',
    i18n: 'search',
    icon: 'SearchIcon',
    slug: 'commercial-admin-search'
  },
  {
    header: 'Roles Commercial admin',
    i18n: 'Roles',
    role: 'commercial-admin',
    icon: 'Edit3Icon',
    items: [
      {
        url: '/commercial-admin/accounts/sales',
        name: 'Sales',
        i18n: 'Sales',
        slug: 'sales',
        icon: 'TrendingUpIcon'
      },
      {
        url: '/commercial-admin/accounts/customer-support',
        name: 'Customer Support',
        i18n: 'Customer Support',
        slug: 'customer support',
        icon: 'PhoneCallIcon'
      },
      {
        url: '/commercial-admin/accounts/growth-hackers',
        name: 'Growth Hacking',
        i18n: 'Growth Hacking',
        slug: 'growth hacking',
        icon: 'UsersIcon'
      },
      {
        url: '/commercial-admin/accounts/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon'
      }
    ]
  }
]
